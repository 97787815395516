export default function loadPage(selector = 'body', callback = () => {}, devMode = false) {
  // Skip Loader if in devMode
  if (devMode) {
    return
  }

  if (!document.querySelector('body')) {
    return
  }

  const allImagesOnPage = [].slice.call(document.querySelectorAll(`${selector} img`))
  const allVideosOnPage = [].slice.call(document.querySelectorAll(`${selector} video`))
  const allElementsOnPage = [...allImagesOnPage, ...allVideosOnPage]
  const nbElements = allElementsOnPage.length
  let nbElementsLoaded = 0

  const triggerIsLoaded = () => {
    setTimeout(() => {
      // document.querySelector('.loader').style.height = '100%'
      setTimeout(() => {
        callback()
      }, 755)
    }, 1500)
  }

  // console.log({ allElementsOnPage, nbElements, nbElementsLoaded })

  if (!nbElements) {
    callback()
  }

  allElementsOnPage.forEach((item) => {
    const elem = item
    // console.log({ elem }, elem.tagName)

    if (elem.tagName === 'IMG') {
      elem.onload = () => {
        nbElementsLoaded += 1
        //   document.querySelector('.loader').style.height = `${Math.floor((nbElementsLoaded / nbElements) * 100)}%`
        // console.log(nbElementsLoaded)
        if (nbElementsLoaded === nbElements) {
          // console.log({ nbElementsLoaded })
          triggerIsLoaded()
        }
      }
    }

    if (elem.tagName === 'VIDEO') {
      elem.onloadedmetadata = () => {
        nbElementsLoaded += 1
        //   document.querySelector('.loader').style.height = `${Math.floor((nbElementsLoaded / nbElements) * 100)}%`

        if (nbElementsLoaded === nbElements) {
          // console.log({ nbElementsLoaded })
          triggerIsLoaded()
        }
      }
    }
  })
}
