<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>

    <div class="splash-wrap">
      <div class="splash-logo">
        <img src="@/assets/img/splash-logo.svg" alt="Air Tronic" />
        <div class="loader"></div>
        <img src="@/assets/img/splash-logo-2.svg" class="splash-color" alt="Air Tronic" />
      </div>
    </div>
  </div>
</template>
<script>

import { isNil } from 'lodash'

export default {
    watch: {
      $route: {
        handler($route) {
          if (!isNil($route)) {
            if ($route.meta.lang === 'en') {
              this.$i18n.locale = 'en'
            } else {
              this.$i18n.locale = 'fr'
            }
          }
        },
        immediate: true,
      },
    },
  }
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/typos.scss';
@import '@/theme/home.scss';
@import '@/theme/flickity.scss';
@import '@/theme/animations-layer.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

img {
  display: block;
  height: auto;
  width: 100%;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}
</style>
