<template>
  <div class="page-wrapper">

    <div class="success-message" v-if="$route.query.success">
      <p v-if="$route.meta.lang === 'fr'">
        Merci, Votre message a bien été envoyé.
      </p>
      <p v-if="$route.meta.lang === 'en'">
        Thank you, Your message has been sent.
      </p>
    </div>

    <header class="navbar">
      <div class="left">
        <svg class="logo" viewBox="0 0 216 129" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M102.87 128.25H113.12C128.01 128.25 137 120.37 137 101.75V90.77H119.23V96.48C119.23 106.94 115.3 110.48 108 110.48C100.7 110.48 96.76 106.94 96.76 96.48V90.77H78.99V101.75C78.99 120.19 87.98 128.25 102.87 128.25Z"
            fill="#0086CA"
          />
          <path d="M137.01 73H119.24V90.76H137.01V73Z" fill="#6A7298" />
          <path d="M96.76 73H78.99V90.76H96.76V73Z" fill="#6A7298" />
          <path d="M137.01 55.24H119.24V73H137.01V55.24Z" fill="#9E5B6A" />
          <path d="M96.76 55.24H78.99V73H96.76V55.24Z" fill="#9E5B6A" />
          <path d="M137.01 37.49H119.24V55.25H137.01V37.49Z" fill="#C13C3E" />
          <path d="M96.76 37.49H78.99V55.25H96.76V37.49Z" fill="#C13C3E" />
          <path
            d="M96.76 37.48V31.75C96.76 21.31 100.7 17.76 108 17.76C115.3 17.76 119.23 21.3 119.23 31.75V37.48H137V26.5C137 8.05 128.01 0 113.12 0H102.87C87.98 0 78.99 8.05 78.99 26.5V37.48H96.76Z"
            fill="#DD0B15"
          />
          <path class="switch-color" d="M33.5 49.71H27.33V77.56H33.5V49.71Z" fill="white" />
          <path class="switch-color" d="M78.8 55.24H70.33V49.71H93.45V55.24H84.97V77.56H78.8V55.24Z" fill="white" />
          <path
            class="switch-color"
            d="M171.23 67.82L170.83 59.06V49.71H177.08V77.56H171.75L157.9 59.74L158.14 68.89V77.56H151.86V49.71H157.19L171.23 67.82Z"
            fill="white"
          />
          <path class="switch-color" d="M188.45 49.71H182.29V77.56H188.45V49.71Z" fill="white" />
          <path
            class="switch-color"
            d="M136.48 49.08H134.41C128.4 49.08 123.48 54 123.48 60.01V67.35C123.48 73.36 128.4 78.28 134.41 78.28H136.48C142.49 78.28 147.41 73.36 147.41 67.35V60.01C147.41 54 142.49 49.08 136.48 49.08ZM136.48 55.24C139.11 55.24 141.25 57.38 141.25 60.01V67.35C141.25 69.98 139.11 72.12 136.48 72.12H134.41C131.78 72.12 129.64 69.98 129.64 67.35V60.01C129.64 57.38 131.78 55.24 134.41 55.24H136.48Z"
            fill="white"
          />
          <path
            class="switch-color"
            d="M12.34 58.99L15.47 68.41H9.21L12.34 58.99ZM9.26 49.71L0 77.56H6.17L7.47 73.66H17.22L18.51 77.56H24.68L15.43 49.71H9.26Z"
            fill="white"
          />
          <path
            class="switch-color"
            d="M56.09 59.26C56.09 59.71 56.01 60.16 55.85 60.61C55.69 61.06 55.45 61.48 55.11 61.86C54.78 62.25 54.35 62.56 53.84 62.79C53.32 63.03 52.71 63.15 51.99 63.15H44.91V55.03H51.91C52.63 55.03 53.25 55.16 53.78 55.43C54.31 55.7 54.74 56.03 55.07 56.42C55.4 56.82 55.65 57.27 55.83 57.77C56 58.27 56.09 58.76 56.09 59.24V59.26ZM61.96 61.71C62.13 60.86 62.22 59.99 62.22 59.09C62.22 57.87 62 56.7 61.57 55.57C61.13 54.44 60.47 53.44 59.6 52.57C58.72 51.69 57.64 51 56.36 50.48C55.07 49.96 53.59 49.71 51.92 49.71H38.71V77.56H44.92V68.41H51.84C51.94 68.41 52.02 68.39 52.12 68.38L55.6 77.57H62.22L58.12 66.74C58.38 66.58 58.66 66.43 58.89 66.27C58.94 66.24 58.99 66.2 59.03 66.17C59.34 65.94 59.6 65.69 59.85 65.44C60.63 64.65 61.22 63.74 61.66 62.7C61.78 62.38 61.89 62.06 61.95 61.73"
            fill="white"
          />
          <path
            class="switch-color"
            d="M114.15 59.26C114.15 59.71 114.07 60.16 113.91 60.61C113.75 61.06 113.51 61.48 113.17 61.86C112.84 62.25 112.41 62.56 111.9 62.79C111.38 63.03 110.77 63.15 110.05 63.15H102.97V55.03H109.97C110.69 55.03 111.31 55.16 111.84 55.43C112.37 55.7 112.8 56.03 113.13 56.42C113.46 56.82 113.71 57.27 113.89 57.77C114.06 58.27 114.15 58.76 114.15 59.24V59.26ZM120.02 61.71C120.19 60.86 120.28 59.99 120.28 59.09C120.28 57.87 120.06 56.7 119.62 55.57C119.18 54.44 118.52 53.44 117.65 52.57C116.77 51.69 115.69 51 114.41 50.48C113.12 49.96 111.64 49.71 109.97 49.71H96.76V77.56H102.96V68.41H109.88C109.98 68.41 110.06 68.39 110.16 68.38L113.64 77.57H120.26L116.16 66.74C116.42 66.58 116.7 66.43 116.93 66.27C116.98 66.24 117.03 66.2 117.07 66.17C117.38 65.94 117.64 65.69 117.89 65.44C118.67 64.65 119.26 63.74 119.7 62.7C119.82 62.38 119.93 62.06 119.99 61.73"
            fill="white"
          />
          <path
            class="switch-color"
            d="M210.53 68.41C210.03 70.51 208.15 72.07 205.9 72.07H203.83C201.2 72.07 199.06 69.93 199.06 67.3V59.96C199.06 57.33 201.2 55.19 203.83 55.19H205.9C208.15 55.19 210.03 56.76 210.53 58.86L216 55.8C214.35 51.84 210.44 49.04 205.9 49.04H203.83C197.82 49.04 192.9 53.96 192.9 59.97V67.31C192.9 73.32 197.82 78.24 203.83 78.24H205.9C210.44 78.24 214.35 75.44 216 71.48L210.53 68.42V68.41Z"
            fill="white"
          />
          <path
            class="switch-color"
            d="M60.89 95.65C59.98 96.54 58.81 96.96 57.54 96.96C54.26 96.96 52.88 94.7 52.86 92.41C52.85 90.1 54.35 87.76 57.54 87.76C58.74 87.76 59.87 88.21 60.78 89.11L59.67 90.18C59.09 89.61 58.31 89.35 57.54 89.35C55.41 89.35 54.49 90.94 54.5 92.41C54.51 93.87 55.36 95.4 57.54 95.4C58.31 95.4 59.18 95.09 59.76 94.51L60.89 95.66V95.65Z"
            fill="white"
          />
          <path class="switch-color" d="M63.07 87.94V96.76H69.27V95.22H64.74V87.94H63.07Z" fill="white" />
          <path class="switch-color" d="M73.49 87.94H71.84V96.76H73.49V87.94Z" fill="white" />
          <path
            class="switch-color"
            d="M83.89 87.95L81.13 91.74L78.37 87.95H76.45V96.77H78.11V90.36L80.92 94.2H81.25L84.13 90.37V96.77H85.79V87.95H83.89Z"
            fill="white"
          />
          <path
            class="switch-color"
            d="M92.69 89.79L94.33 93.55H91.05L92.69 89.79ZM91.78 87.94L87.82 96.76H89.62L90.38 95.1H94.99L95.73 96.76H97.55L93.59 87.94H91.78Z"
            fill="white"
          />
          <path class="switch-color" d="M97.72 87.94V89.45H100.52V96.76H102.19V89.45H104.99V87.94H97.72Z" fill="white" />
          <path class="switch-color" d="M109.04 87.94H107.39V96.76H109.04V87.94Z" fill="white" />
          <path
            class="switch-color"
            d="M117.68 90.07C117.38 89.57 116.55 89.09 115.59 89.09C114.36 89.09 113.76 89.61 113.76 90.26C113.76 91.03 114.67 91.24 115.73 91.37C117.57 91.6 119.28 92.08 119.28 94.18C119.28 96.15 117.54 96.99 115.58 96.99C113.78 96.99 112.39 96.43 111.74 94.82L113.13 94.1C113.52 95.07 114.54 95.5 115.61 95.5C116.68 95.5 117.64 95.14 117.64 94.18C117.64 93.35 116.77 93.01 115.6 92.88C113.8 92.67 112.13 92.19 112.13 90.21C112.13 88.4 113.92 87.65 115.55 87.64C116.92 87.64 118.35 88.03 119.02 89.39L117.7 90.07H117.68Z"
            fill="white"
          />
          <path
            class="switch-color"
            d="M125.59 89.79L127.23 93.55H123.95L125.59 89.79ZM124.68 87.94L120.72 96.76H122.52L123.28 95.1H127.89L128.63 96.76H130.45L126.49 87.94H124.68Z"
            fill="white"
          />
          <path class="switch-color" d="M130.63 87.94V89.45H133.42V96.76H135.09V89.45H137.89V87.94H130.63Z" fill="white" />
          <path class="switch-color" d="M141.94 87.94H140.29V96.76H141.94V87.94Z" fill="white" />
          <path
            class="switch-color"
            d="M145.97 92.44C146.01 93.89 146.79 95.44 148.87 95.44C150.95 95.44 151.73 93.88 151.76 92.43C151.78 90.94 150.95 89.28 148.87 89.28C146.79 89.28 145.93 90.96 145.97 92.44ZM153.39 92.4C153.37 94.68 151.97 96.96 148.87 96.96C145.77 96.96 144.33 94.73 144.33 92.41C144.33 90.09 145.82 87.76 148.87 87.76C151.92 87.76 153.42 90.09 153.39 92.4Z"
            fill="white"
          />
          <path class="switch-color" d="M161.48 87.92V93.41L157.16 87.94H155.81V96.76H157.47V90.81L162.11 96.77V96.76H163.14V87.92H161.48Z" fill="white" />
          <path d="M169.79 88.89V95.82" stroke="#DD0B15" stroke-width="1.95" stroke-miterlimit="10" stroke-linecap="round" />
          <path d="M46.21 88.89V95.82" stroke="#0086CA" stroke-width="1.95" stroke-miterlimit="10" stroke-linecap="round" />
        </svg>

        <nav>
          <a href="#expertises" v-scroll-to:-120>{{ $t('menu-1') }}</a>
          <a href="#about" v-scroll-to:-120>{{ $t('menu-2') }}</a>
          <a href="#job" v-scroll-to:-120>{{ $t('menu-3') }}</a>
        </nav>
      </div>

      <div class="right">
        <a href="tel:5143214144">514 321-4144</a>
        <p>|</p>
        <a href="/en" class="lang" v-if="$route.meta.lang === 'fr'">EN</a>
        <a href="/" class="lang" v-if="$route.meta.lang === 'en'">FR</a>
        <p>|</p>
        <a href="https://www.facebook.com/airtronicclimatisation" target="_blank" class="social">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0C5.373 0 0 5.373 0 12C0 18.016 4.432 22.984 10.206 23.852V15.18H7.237V12.026H10.206V9.927C10.206 6.452 11.899 4.927 14.787 4.927C16.17 4.927 16.902 5.03 17.248 5.076V7.829H15.278C14.052 7.829 13.624 8.992 13.624 10.302V12.026H17.217L16.73 15.18H13.624V23.877C19.481 23.083 24 18.075 24 12C24 5.373 18.627 0 12 0Z"/>
          </svg>
        </a>
        <a href="#" class="cta" @click.prevent="showContact">
          <p>{{ $t('contact') }}</p>
        </a>
      </div>
    </header>

    <section class="hero">
      <div class="img">
        <h1 class="title small" data-inview="fadeInUp" data-delay="200">Air Tronic Climatisation</h1>
        <h2 class="title" data-inview="fadeInUp" data-delay="300" v-html="$t('hero')"></h2>
        <a href="#expertises" v-scroll-to:-120>
          <img src="@/assets/img/arrow-down.svg" class="arrow-down" alt="Arrow down" />
        </a>
        <p class="rbq">Licence RBQ : 5705-3911-01</p>
        <div class="overlay"></div>
      </div>
    </section>

    <section class="expertises" id="expertises">
      <div class="title-wrap" data-inview="fadeInUp" data-delay="200">
        <h2 class="title medium" v-html="$t('years-title')"></h2>
        <a href="#" class="cta" @click.prevent="showContact">
          <p>{{ $t('contact') }}</p>
        </a>
      </div>

      <div class="expertise left">
        <div class="img" data-inview="revealLeft" data-delay="200">
          <div class="overlay"></div>
          <img src="@/assets/img/installation-3.jpg" alt="Installation" />
        </div>
        <div class="data">
          <h3 class="title small" data-inview="fadeIn" data-delay="200">{{ $t('installation-title') }}</h3>
          <p class="text" data-inview="fadeIn" data-delay="200">
            {{ $t('installation-desc') }}
          </p>
        </div>
      </div>

      <div class="expertise right">
        <div class="data">
          <h3 class="title small" data-inview="fadeIn" data-delay="200">{{ $t('entretient-title') }}</h3>
          <p class="text" data-inview="fadeIn" data-delay="200">
            {{ $t('entretient-desc') }}
          </p>
        </div>
        <div class="img" data-inview="revealRight" data-delay="400">
          <div class="overlay"></div>
          <img src="@/assets/img/entretient-3.jpg" alt="Entretien" />
        </div>
      </div>

      <div class="expertise left">
        <div class="img" data-inview="revealLeft" data-delay="200">
          <div class="overlay"></div>
          <img src="@/assets/img/service-3.jpg" alt="Service" />
        </div>
        <div class="data">
          <h3 class="title small" data-inview="fadeIn" data-delay="200">{{ $t('service-title') }}</h3>
          <p class="text" data-inview="fadeIn" data-delay="200">
            {{ $t('service-desc') }}
          </p>
        </div>
      </div>

      <div class="expertise right">
        <div class="data">
          <h3 class="title small" data-inview="fadeIn" data-delay="200">{{ $t('controle-title') }}</h3>
          <p class="text" data-inview="fadeIn" data-delay="200">
            {{ $t('controle-desc') }}
          </p>
        </div>
        <div class="img" data-inview="revealRight" data-delay="400">
          <div class="overlay"></div>
          <img src="@/assets/img/controle-3.jpg" alt="Contrôle" />
        </div>
      </div>
    </section>

    <section class="temoignages">
      <div class="title-wrap">
        <h2 class="title medium">{{ $t('client-title') }}</h2>
      </div>
      <div class="clients-wrap">
        <div class="client" data-inview="fadeInUp" data-delay="100">
          <img src="@/assets/img/client-1.jpg" alt="Complexe Enviro Connexions">
        </div>
        <div class="client" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/client-2.jpg" alt="L'appartement Hôtel">
        </div>
        <div class="client" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/client-3.jpg" alt="Le Square Phillips">
        </div>
        <div class="client" data-inview="fadeInUp" data-delay="400">
          <a href="https://jeanfortin.com/" target="_blank"><img src="@/assets/img/client-4-new.jpg" alt="Jean Fortin"></a>
        </div>
        <div class="client" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/client-5.jpg" alt="Caisse Desjardins">
        </div>
        <div class="client" data-inview="fadeInUp" data-delay="600">
          <img src="@/assets/img/client-6.jpg" alt="Redbourne">
        </div>
      </div>
      <!-- <div class="temoignages-box-wrap">
        <div class="temoignages-box" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/quote.svg" alt="Quote" />
          <p class="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco.
          </p>
          <p class="text name"><span>Mathieu</span><br />Montréal</p>
        </div>
        <div class="temoignages-box" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/quote.svg" alt="Quote" />
          <p class="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco.
          </p>
          <p class="text name"><span>Mathieu</span><br />Montréal</p>
        </div>
        <div class="temoignages-box" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/quote.svg" alt="Quote" />
          <p class="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco.
          </p>
          <p class="text name"><span>Mathieu</span><br />Montréal</p>
        </div>
      </div> -->
    </section>

    <section class="about" id="about">
      <div class="intro-wrap">
        <div class="img" data-inview="revealRight" data-delay="200">
          <div class="overlay"></div>
          <img src="@/assets/img/about.jpg" alt="Air Tronic" />
        </div>
        <div class="data" data-inview="fadeIn" data-delay="600">
          <img src="@/assets/img/logo-symbol.svg" alt="Air Tronic logo symbol" />
          <h2 class="title" v-html="$t('expertise-years-title')"></h2>
        </div>
      </div>

      <div class="content-wrap">
        <div class="title-wrap">
          <h3 class="title small">{{ $t('histoire-title') }}</h3>
          <a href="#" class="cta" @click.prevent="showContact">
            <p>{{ $t('contact') }}</p>
          </a>
        </div>
        <div class="story-wrap">
          <div class="story">
            <p class="text" v-html=" $t('histoire-content-1') "></p>
          </div>
          <div class="story">
            <p class="text" v-html=" $t('histoire-content-2') "></p>
          </div>
        </div>
      </div>

      <div class="mission-wrap">
        <div class="mission">
          <div class="top">
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
          </div>
          <h3 class="title" data-inview="fadeInUp" data-delay="200">{{ $t('mission-title') }}</h3>
          <p class="text" data-inview="fadeInUp" data-delay="400">
            {{ $t('mission-desc') }}
          </p>
          <div class="bottom">
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
          </div>
        </div>
      </div>

      <div class="valeurs">
        <div class="title-wrap">
          <h2 class="title small">{{ $t('valeur-title') }}</h2>
        </div>
        <div class="valeur-box" data-inview="fadeInUp" data-delay="200">
          <p>{{ $t('valeur-1') }}</p>
        </div>
        <div class="valeur-box" data-inview="fadeInUp" data-delay="300">
          <p>{{ $t('valeur-2') }}</p>
        </div>
        <div class="valeur-box" data-inview="fadeInUp" data-delay="400">
          <p>{{ $t('valeur-3') }}</p>
        </div>
        <div class="valeur-box" data-inview="fadeInUp" data-delay="500">
          <p>{{ $t('valeur-4') }}</p>
        </div>
      </div>
    </section>

    <section class="team-carrousel" id="job">
      <div class="title-wrap">
        <h2 class="title medium" v-html="$t('carriere-title')"></h2>
        <a href="#" class="cta blue" @click.prevent="showJobForm">
          <p>{{ $t('carriere-cta') }}</p>
        </a>
      </div>

      <div class="main-carousel">
        <div class="carousel-cell">
          <img src="@/assets/img/team-1.jpg" class="desktop" alt="Air Tronic équipe" />
          <img src="@/assets/img/team-mobile-1.jpg" class="mobile" alt="Air Tronic équipe" />
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/team-2.jpg" class="desktop" alt="Air Tronic équipe" />
          <img src="@/assets/img/team-mobile-2.jpg" class="mobile" alt="Air Tronic équipe" />
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/team-3.jpg" class="desktop" alt="Air Tronic équipe" />
          <img src="@/assets/img/team-mobile-3.jpg" class="mobile" alt="Air Tronic équipe" />
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/team-4.jpg" class="desktop" alt="Air Tronic équipe" />
          <img src="@/assets/img/team-mobile-4.jpg" class="mobile" alt="Air Tronic équipe" />
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/team-5.jpg" class="desktop" alt="Air Tronic équipe" />
          <img src="@/assets/img/team-mobile-5.jpg" class="mobile" alt="Air Tronic équipe" />
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/team-6.jpg" class="desktop" alt="Air Tronic équipe" />
          <img src="@/assets/img/team-mobile-6.jpg" class="mobile" alt="Air Tronic équipe" />
        </div>
      </div>
    </section>

    <section class="avantages">
      <div class="title-wrap">
        <h2 class="title small">{{ $t('avantage-title') }}</h2>
      </div>
      <div class="avantage-box" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/check.svg" alt="Check" />
        <p>{{ $t('avantage-1') }}</p>
      </div>
      <div class="avantage-box" data-inview="fadeInUp" data-delay="300">
        <img src="@/assets/img/check.svg" alt="Check" />
        <p>{{ $t('avantage-2') }}</p>
      </div>
      <div class="avantage-box" data-inview="fadeInUp" data-delay="400">
        <img src="@/assets/img/check.svg" alt="Check" />
        <p>{{ $t('avantage-3') }}</p>
      </div>
    </section>

    <footer class="main-footer">
      <div class="cta-bloc">
        <img src="@/assets/img/logo-symbol.svg" alt="Air Tronic logo symbol" data-inview="fadeInUp" data-delay="200" />
        <h2 class="title medium" data-inview="fadeInUp" data-delay="300" v-html="$t('final-cta-title')"></h2>
        <a href="#" class="cta" @click.prevent="showContact" data-inview="fadeInUp" data-delay="400">
          <p>{{ $t('contact') }}</p>
        </a>
      </div>

      <div class="logos-wrap">
        <div class="img">
          <img src="@/assets/img/logo-partenaire-1.jpg" alt="logo CMMTQ" />
        </div>
        <div class="img">
          <img src="@/assets/img/logo-partenaire-2.jpg" alt="logo CCTAR" />
        </div>
        <div class="img">
          <img src="@/assets/img/logo-partenaire-3.jpg" alt="logo AECQ" />
        </div>
      </div>

      <div class="credits">
        <p>{{ $t('copy') }}</p>
        <a href="http://jnv.dev/" target="_blank">
          <img src="@/assets/img/jnv.svg" alt="logo J&V" />
        </a>
      </div>
    </footer>

    <section class="overlay-form" :class="{ active: contactIsOpen }">
      <div class="form-wrap">
        <img src="@/assets/img/close.svg" class="close" alt="Close" @click.prevent="showContact" />
        <h2 class="title small">{{ $t('contact') }}</h2>
        <a href="tel:5143214144" class="link">
          <div class="icon">
            <img src="@/assets/img/phone.svg" alt="Phone" />
          </div>
          <p>514 321-4144</p>
        </a>
        <a href="mailto:soumission@airtronic-climatisation.com" class="link">
          <div class="icon">
            <img src="@/assets/img/mail.svg" alt="Mail" />
          </div>
          <p>soumission@airtronic-climatisation.com</p>
        </a>
        <a
          href="https://www.google.com/maps/place/8575+Rue+Lafrenaie,+Saint-L%C3%A9onard,+QC+H1P+2B6,+Canada/@45.5998889,-73.5912198,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc91e57609457eb:0x39ed7e27876e9533!8m2!3d45.5998889!4d-73.5890311"
          class="link"
          target="_blank"
        >
          <div class="icon">
            <img src="@/assets/img/map.svg" alt="Map" />
          </div>
          <p>8575 Rue Lafrenaie, Saint-Léonard, QC</p>
        </a>
        <form
          action="/?success=1"
          name="Contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="Contact" />

          <input type="text" name="Nom" :placeholder="$t('form-name')" />
          <input type="mail" name="Courriel" :placeholder="$t('form-mail')" />
          <input type="text" name="Téléphone" :placeholder="$t('form-phone')" />
          <textarea placeholder="Message" name="Message"></textarea>
          <input type="submit" :value="$t('form-send')" />
        </form>
      </div>
      <div class="overlay" @click.prevent="showContact"></div>
    </section>

    <section class="overlay-form" :class="{ active: jobIsOpen }">
      <div class="form-wrap">
        <img src="@/assets/img/close.svg" class="close" alt="Close" @click.prevent="showJobForm" />
        <h2 class="title small">{{ $t('carriere-cta') }}</h2>
        <form
          action="/?success=1"
          name="Carriere"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          enctype="multipart/form-data"
        >
          <input type="hidden" name="form-name" value="Carriere" />

          <input type="text" name="Nom" :placeholder="$t('form-name')" />
          <input type="mail" name="Courriel" :placeholder="$t('form-mail')" />
          <input type="text" name="Téléphone" :placeholder="$t('form-phone')" />
          <textarea name="Expérience" :placeholder="$t('form-experience')"></textarea>
          <div class="input">
            <input type="file" name="c.v" placeholder="C.V." />
            <p>C.V.</p>
          </div>
          <input type="submit" :value="$t('form-send')" />
        </form>
      </div>
      <div class="overlay" @click.prevent="showJobForm"></div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Flickity from 'flickity'
import loadPage from '@/misc/helpers'
import initInViewAnimations from '@/misc/animations-layer'

export default {
  head() {
    return {
      title: {
        inner: 'Air Tronic climatisation',
      },
      meta: [
        {
          name: 'description',
          content: 'Entreprise spécialisée en climatisation, ventilation et chauffage depuis plus de 40 ans. Profitez de notre expertise et favorisez une approche humaine.',
          id: 'desc',
        },
        { name: 'application-name', content: 'Air Tronic climatisation' },
        { name: 'twitter:title', content: 'Entreprise spécialisée en climatisation, ventilation et chauffage depuis plus de 40 ans. Profitez de notre expertise et favorisez une approche humaine.' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Entreprise spécialisée en climatisation, ventilation et chauffage depuis plus de 40 ans. Profitez de notre expertise et favorisez une approche humaine.',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Air Tronic climatisation' },
        { property: 'og:site_name', content: 'Air Tronic climatisation' },
        {
          property: 'og:description',
          content: 'Entreprise spécialisée en climatisation, ventilation et chauffage depuis plus de 40 ans. Profitez de notre expertise et favorisez une approche humaine.',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://airtronicclimatisation.com/img/og-image.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://airtronicclimatisation.com/img/og-image.jpg',
        },
      ],
    }
  },
  data() {
    return {
      teamCarousel: null,
      contactIsOpen: false,
      jobIsOpen: false,
    }
  },
  methods: {
    showJobForm() {
      if (this.jobIsOpen) {
        this.jobIsOpen = false
      } else {
        this.jobIsOpen = true
      }
    },
    showContact() {
      if (this.contactIsOpen) {
        this.contactIsOpen = false
      } else {
        this.contactIsOpen = true
      }
    },
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.navbar')) {
        return
      }

      if (st >= 10) {
        document.querySelector('.navbar').classList.add('sticky')
      } else {
        document.querySelector('.navbar').classList.remove('sticky')
      }
    },
  },
  mounted() {

    if (document.querySelector('.success-message')) {
      setTimeout(() => {
        document.querySelector('.success-message').classList.add('fade')
      }, 7999)
    }

    loadPage(
      'body',
      () => {
        this.isLoaded = true

        document.querySelector('.splash-wrap').classList.add('is-loaded')

        setTimeout(() => {
          initInViewAnimations()
          this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: true,
            pageDots: false,
          })
        }, 755)
      },
      false
    )
  },
  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },
  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
