const trans = {
  fr: {
    'menu-1': 'Expertise',
    'menu-2': 'À propos',
    'menu-3': 'Carrière',
    'contact': 'Contactez-nous',

    'hero': 'Climatisation / Ventilation / Chauffage',

    'years-title': 'À votre service<br />depuis plus de 20 ans !',

    'installation-title': 'Installation',
    'installation-desc': 'Notre équipe de professionnels expérimentés saura vous guider dans l’élaboration, la réalisation et/ou la mise en œuvre de vos projets. Tenant compte des contraintes budgétaires et techniques, elle vous proposera les meilleures options vous garantissant un travail de qualité.  Nous travaillons avec les plus grands distributeurs du milieu pour vous offrir un vaste choix d’équipement.',

    'entretient-title': 'Entretien',
    'entretient-desc': 'Pour une meilleure qualité d’air, une capacité énergétique maximale et la prévention de problèmes majeurs, nous recommandons et offrons un service d’entretien préventif sur mesure et adapté à vos besoins.  Notre expérience nous a prouvé qu’un entretien régulier optimise la performance et la longévité de vos équipements.  Nos clients bénéficiaires d’un contrat d’entretien sont assurés d’un service prioritaire en tout temps, particulièrement apprécié en situation d’excès thermiques.',

    'service-title': 'Service',
    'service-desc': 'Si un bris ou une défaillance de vos équipements survient, vous pouvez compter sur une intervention rapide, efficace et professionnelle de notre équipe. Et en cas d’urgence, nous sommes rejoignables 24/7.',

    'controle-title': 'Contrôle',
    'controle-desc': 'Pour allier confort, efficacité et économie d’énergie, le bon choix des systèmes de contrôle est primordial. Système à simple zone, thermostat à haut voltage ou sonde électronique sophistiquée, Air Tronic saura vous conseiller dans le choix d’une régulation automatique adaptée à vos besoins.',

    'client-title': 'Quelques précieux clients',

    'expertise-years-title': 'Air Tronic Climatisation,<br />c’est <span>40 ans</span> d’expertise !',

    'histoire-title': 'Notre histoire',
    'histoire-content-1': '<span>C’est en 1979,</span><br />initiés et guidés par leur père, que les frères Jacques et Gilles Charron entament leur carrière dans le domaine de la climatisation. Pendant plus de 20 ans ils développent une connaissance accrue du milieu et acquièrent une solide expertise au sein de l’entreprise paternelle AIRMEC Climatisation.',
    'histoire-content-2': '<span>Mais c’est en 1998,</span><br />qu’ils se lancent un grand défi et fondent Air Tronic Climatisation.  Ils ont depuis mené à terme d’innombrables projets et servi fièrement leur fidèle clientèle. Suite au départ à la retraite de Jacques, Gilles est très heureux de pouvoir compter sur l’appui de sa fille Mishèle et son fils Philippe pour perpétuer la mission de l’entreprise familiale.  C’est leur grand-père Jean qui en serait fier !',

    'mission-title': 'Notre mission',
    'mission-desc': 'Mettre notre expertise et notre large réseau de partenaires à votre service et développer des relations de confiance durables.',
  
    'valeur-title': 'Nos valeurs',
    'valeur-1': 'Transparence',
    'valeur-2': 'Expertise',
    'valeur-3': 'Entraide',
    'valeur-4': 'Agilité',
  
    'carriere-title': 'Une entreprise où<br />l’on se sent chez soi !',
    'carriere-cta': 'APPLIQUEZ MAINTENANT',

    'form-name': 'Nom complet',
    'form-mail': 'Courriel',
    'form-phone': 'Téléphone',
    'form-experience': 'Décrivez votre expérience',
    'form-send': 'Envoyer',

    'avantage-title': 'RETENEZ D’AIR TRONIC ...',
    'avantage-1': 'Une équipe engagée',
    'avantage-2': 'Des projets diversifiés',
    'avantage-3': 'Un esprit familial',

    'final-cta-title': 'Profitez de notre expertise<br />Favorisez une approche humaine',

    'copy': '© Tous droits réservés - Air Tronic',
  },
  en: {
    'menu-1': 'Expertise',
    'menu-2': 'About',
    'menu-3': 'Career',
    'contact': 'Contact us',

    'hero': 'CONDITIONING / VENTILATION / HEATING',

    'years-title': 'AT YOUR SERVICE<br>FOR MORE THAN 20 YEARS !',

    'installation-title': 'FACILITY',
    'installation-desc': 'Our team of experienced professionals will be able to guide you in the development, realization and/or implementation of your projects. Taking into account budgetary and technical constraints, we will offer you the best options while guaranteeing the highest quality work.  We are aligned with the largest distributors in the industry to provide you a wide choice of equipment.',

    'entretient-title': 'MAINTENANCE',
    'entretient-desc': 'For better air quality, maximum energy capacity and the prevention of major problems, we recommend and offer a preventive service tailored to your needs. Our experience has shown us that regular maintenance optimizes the performance and longevity of your equipment.  Customers with a maintenance contract are assured of priority service at all times, particularly advantageous in situations of excess heat.',

    'service-title': 'Service',
    'service-desc': 'If your equipment breaks or fails, you can count on our team to respond quickly, efficiently and professionally.  And in case of emergency, we are reachable 24/7.',

    'controle-title': 'CONTROL',
    'controle-desc': 'To combine comfort, efficiency and energy savings, the right choice of control systems is essential.  Whether a single zone system, high voltage thermostat or sophisticated electronic probe, Air Tronic will be able to advise you in choosing an automatic regulation adapted to your needs.',
    
    'client-title': 'SOME VALUABLE CUSTOMERS',

    'expertise-years-title': 'AIR TRONIC AIR CONDITIONING WITH OVER <span>40 YEARS</span> OF EXPERTISE !',

    'histoire-title': 'Our Story',
    'histoire-content-1': '<span>It was in 1979,</span><br> introduced and guided by their father, the brothers Jacques and Gilles Charron began their career in the field of air conditioning. For more than 20 years, they developed and increased their knowledge of the environment and acquired a solid expertise within the father company AIRMEC Climatisation.',
    'histoire-content-2': '<span>But it was in 1998,</span><br>that they took on their biggest challenge yet and founded Air Tronic Conditioning.  They have since completed countless projects and proudly served their loyal clientele.  Following Jacques’ retirement, Gilles was very happy to have his daughter Mishèle and his son Philippe join the company to help continue the mission of the family business.  Their grandfather Jean would be very proud !',
  
    'mission-title': 'Our mission',
    'mission-desc': 'To put our expertise and our large network of partners at your service and develop lasting relationships of trust.',
  
    'valeur-title': 'Our Values',
    'valeur-1': 'Transparency',
    'valeur-2': 'Expertise',
    'valeur-3': 'Support',
    'valeur-4': 'Agility',

    'carriere-title': 'A company where<br />you feel at home',
    'carriere-cta': 'Apply now',

    'form-name': 'Full name',
    'form-mail': 'E-mail',
    'form-phone': 'Phone number',
    'form-experience': 'Describe your experience',
    'form-send': 'Send',

    'avantage-title': 'WHEN YOU THINK AIR TRONIC ....',
    'avantage-1': 'A committed team',
    'avantage-2': 'Diversified projects',
    'avantage-3': 'A family spirit',

    'final-cta-title': 'BENEFIT FROM OUR EXPERTISE<br>PROMOTE A HUMAN APPROACH',

    'copy': '© All rights reserved - Air Tronic',
  },
}

export default trans
